window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

 try {
  window.Popper = require('popper.js').default;
  window.$ = window.jQuery = require('jquery');
} catch (e) {}

require('jquery-lazy');
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/modal';
import 'slick-carousel';
import 'lity';
import 'jquery-validation';