$(document).ready(function () {
  $('.slides').slick({
      nextArrow: $(".slider-next"),
      prevArrow: $(".slider-prev"),
      asNavFor: $(".slide-dots")
  });

  $('.slide-dots').slick({
      slidesToShow: 3,
      asNavFor: '.slides',
      focusOnSelect: true
  });

  $(".mob_show").on("click", function () {
    $(".mob").show(200);
    $(".mob_show").remove();
  });
});