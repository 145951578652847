(function($){
$(document).ready(function(){
  $('.lazy').lazy({
    threshold: $(window).height() * 0.5,
    visibleOnly: true,
    combined: true,
    delay: 5000,
    beforeLoad: function(element) {
      return element.addClass('lazy-loaded').removeClass('lazy-pending');
    }
  });

  $('.lazy-div').lazy({
    lazyLoadDiv: function(element) {
     element.addClass('lazy-div-loaded');
    }
  });

  $(document).on('show.bs.modal', '.modal', function() {
    return $('.lazy', this).lazy({
      bind: "event",
      delay: 0
    });
  });
  
  $(document).on('show.bs.collapse', '.modal', function() {
    return $('.lazy', this).lazy({
      bind: "event",
      delay: 0
    });
  });

});
  
})(jQuery);