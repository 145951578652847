(function($){
  $(document).ready(function(){
    window.dataLayer = window.dataLayer || [];
    const $sampleReportsModal = $('#sample-reports-form');
    const $sampleReportsModalImage = $('#sample-reports-form-image');
    $(document).on('click', '[sample-reports-modal-show]', function(e){
      e.preventDefault();
      const report_type = $(this).attr('data-report-type');
      const html = generateImage($(this).attr('data-report-image'));
      //$sampleReportsModal.find('input[placeholder="Report Type"]').val(report_type);
      _learnq.push(['identify', {
        'sampleReportType' : report_type
      }]);
      $sampleReportsModalImage.html(html);
      $sampleReportsModal.attr('data-title', $(this).attr('data-title'));
      $sampleReportsModal.modal('show');
    });

    $sampleReportsModal.on('hidden.bs.modal', function (event) {
      $sampleReportsModal.attr('data-title', '');
      $sampleReportsModalImage.html('');
    });
  
    function generateImage(src) {
      var imageSrc = src;
      var imageWrap = document.createElement('div');
      imageWrap.classList.add('report-modal--image');
      imageWrap.classList.add('text-center');
      var image = document.createElement('img');
      image.width = 298;
      image.height = 400;
      image.src = imageSrc;
      image.classList.add('img-fluid');
      imageWrap.append(image);
      for (var i=1;i<=3;i++) {
        const div = document.createElement('div');
        div.classList.add('image-box-' + i);
        imageWrap.append(div);
      }
      return imageWrap;
    }

    var $largeBlock = $('section.section-reports-block');
    if ($largeBlock.length > 0) {
      var processReportsBlockScroll = function() {
        var windowScroll = $(window).scrollTop();
        if (windowScroll > $largeBlock.offset().top) {
          $largeBlock.addClass('has-sticky');
        } else {
          $largeBlock.removeClass('has-sticky');
        }
      }
  
      $(window).on('resize', function(){
        processReportsBlockScroll();
      });
  
      $(window).on('scroll', function(){
        processReportsBlockScroll();
      });
  
      processReportsBlockScroll();

    }

    $('.report-modal').on('shown.bs.modal', function(e){
      window.dataLayer.push({
        event: 'reportModalShown',
        reportModalTitle: $(this).attr('data-title')
      });      
    });

    $('#sample-reports-form').on('shown.bs.modal', function(e){
      window.dataLayer.push({
        event: 'sampleReportModalShown',
        reportModalTitle: $(this).attr('data-title')
      });
    });

    $('.report-modal-cta').on('click', function(e){
      window.dataLayer.push({
        event: 'reportModalCTAClick',
        reportModalTitle: $(this).attr('data-title')
      });
    });

    $('[sample-reports-modal-show]').on('click', function(e){
      window.dataLayer.push({
        event: 'reportModalSampleclick',
        reportModalTitle: $(this).attr('data-title')
      });
    });

    window.addEventListener("klaviyoForms", function(e) { 
      if (e.detail.type == 'submit' && e.detail.formId == 'ThLfsW') {
        window.dataLayer.push({
          event: 'sampleReportModalSubmit',
          reportModalTitle: $sampleReportsModal.attr('data-title'),
          email: e.detail.metaData['$email']
        });
      }

      if (e.detail.type == 'submit' && e.detail.formId == 'RSAiwD') {
        window.dataLayer.push({
          event: 'waitlistFormSubmitted'
        });
      }
    });
    
    $('#waitlistModal').on('shown.bs.modal', function(e){
      window.dataLayer.push({
        event: 'waitlistFormShown'
      });
    });

  });
})(jQuery);