import Cookies from 'js-cookie'
import lity from 'lity';
import axios from 'axios';

(function($){
$(document).ready(function(){
const $stickyFooter = $('#sticky-footer');
const $stickyFooterSpacer = $('#sticky-footer-spacer');

if ($stickyFooter && $stickyFooterSpacer) {

  var handleStickySpacer = function() {
    $stickyFooterSpacer.height($stickyFooter.height() + 'px');
  };

  handleStickySpacer();

  $(window).on('resize', handleStickySpacer);
}

$(document).on('lity:open', function(event, instance) {
  window.history.pushState('popup-open', null, '#video');
  window.history.pushState('popup-tmp', null, null);
  window.addEventListener('popstate', customHideLityModal, { once: true });
});

$(document).on('lity:close', function(event, instance) {
  if (history.state == 'popup-tmp') {
    window.history.back();
  }
});

var hideLityModal = function() {
  $('[data-lity-close]', document).trigger('click');
};

var customHideLityModal = function(event) {
  hideLityModal();

  if (history.state == 'popup-open')  {
    window.history.back();
  }
};

if ($('body').hasClass('theme-calm-blue')) {
  function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
  }

  if (window !== window.parent || inIframe()) {
    $('.btn-cta, .other-coaching-programs-section a').each(function(){
      $(this).attr('target', '_blank');
    });
  }
}

});

const webinarEmailPlay = $('[webinar-email-play]');
const webinarEmailForm = $('[webinar-email-form]');
const webinarFormMessage = $('[webinar-form-message]');

$(document).on('click', '[webinar-email-play]', function(e){
  var videoSource = $('[webinar-email-play]').attr('data-source');
  if (webinarEmailForm.length > 0) {
    if (!Cookies.get('dna_webinar_access')) {
      webinarEmailForm.show();
      webinarEmailPlay.hide();
    } else {
      var lightbox = lity(videoSource);
    }
  } else {
    var lightbox = lity(videoSource);  
  }
});
var videoSource = $('[webinar-email-play]').attr('data-source') || null;

window.addEventListener("klaviyoForms", function(e) { 
  if (e.detail.type == 'submit') {
    if (e.detail.formId == 'TfPbEB') {
      Cookies.set('dna_webinar_access', true, { expires: 30 });
      if (videoSource) {
        webinarEmailPlay.show();
        webinarEmailForm.hide();
        var lightbox = lity(videoSource);
      }
    }
  }
});

if ($('#webinar-email').length > 0) {
  $('#webinar-email').validate();
  $(document).on('click', '#webinar-email button', function(e){
    var videoSource = $('[webinar-email-play]').attr('data-source');
    e.preventDefault();

    if ($('#webinar-email').valid()) {
      axios.post('/webinar/email-gate', {
        first_name: $('#webinar-email').find('input[name="first_name"]').val(),
        last_name: $('#webinar-email').find('input[name="last_name"]').val(),
        email: $('#webinar-email').find('input[name="email"]').val(),
        nid: window.dna_nid
      })
      .then(function (response) {
        Cookies.set('dna_webinar_access', true, { expires: 30 });
        webinarEmailPlay.show();
        webinarEmailForm.hide();
        var lightbox = lity(videoSource);
      })
      .catch(function (error) {
        webinarFormMessage.html('<div class="alert alert-danger">Something wen wrong. Please try again.</div>');
      });
    }
  });
}
})(jQuery);